import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiSelect } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiselect"
    }}>{`UiSelect`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/form/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render select inputs`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/form`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiselect-1"
    }}>{`UiSelect`}</h2>
    <Playground __position={1} __code={'<UiSelect\n  onChange={value => {\n    console.log(value)\n  }}\n  defaultValue=\"\"\n>\n  <option disabled value=\"\">\n    Select a car\n  </option>\n  <option> Ford </option>\n  <option> Chevy </option>\n</UiSelect>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSelect onChange={value => {
        console.log(value);
      }} defaultValue="" mdxType="UiSelect">
    <option disabled value="">
      Select a car
    </option>
    <option> Ford </option>
    <option> Chevy </option>
  </UiSelect>
    </Playground>
    <h2 {...{
      "id": "uiselect-with-selected-value"
    }}>{`UiSelect with selected value`}</h2>
    <Playground __position={2} __code={'<UiSelect label=\"Car\" labelOnTop value=\"Chevy\">\n  <option disabled>Select a car</option>\n  <option>Ford</option>\n  <option>Chevy</option>\n</UiSelect>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSelect label="Car" labelOnTop value="Chevy" mdxType="UiSelect">
    <option disabled>Select a car</option>
    <option>Ford</option>
    <option>Chevy</option>
  </UiSelect>
    </Playground>
    <h2 {...{
      "id": "uiselect-disabled"
    }}>{`UiSelect disabled`}</h2>
    <Playground __position={3} __code={'<UiSelect label=\"Car\" disabled>\n  <option> Ford </option>\n  <option> Chevy </option>\n</UiSelect>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSelect label="Car" disabled mdxType="UiSelect">
    <option> Ford </option>
    <option> Chevy </option>
  </UiSelect>
    </Playground>
    <h2 {...{
      "id": "uitextarea-with-error-state-and-error-message"
    }}>{`UiTextArea with error state and error message`}</h2>
    <Playground __position={4} __code={'<UiSelect label=\"Car\" error=\"Some error\" category=\"error\">\n  <option> Ford </option>\n  <option> Chevy </option>\n</UiSelect>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiSelect,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSelect label="Car" error="Some error" category="error" mdxType="UiSelect">
    <option> Ford </option>
    <option> Chevy </option>
  </UiSelect>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiSelect} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      